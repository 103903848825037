// TODO REFACTOR
//TODO rename -> currently there are two location intefaces

interface LocationNode {
  id: string;
  name: string;
  parentId: string;
  isRoot: boolean;
}

export interface MappedLocation {
  id: string;
  pId: string;
  rootPId: string;
  value: string;
}

export interface LocationTreeNode {
  value: string;
  title: string;
  children?: LocationTreeNode[];
}

export const toLocationTreeNode = (
  locations: LocationNode[],
): LocationTreeNode[] => {
  // all locations where no parent location is found are considered as root (needed for kleeblatt)
  const rootLocations = locations.filter(
    (location) => !locations.find((a) => a.id === location.parentId),
  );

  return rootLocations.map((root) => buildLocationTree(locations, root));
};

export const buildLocationTree = (
  locations: LocationNode[],
  currentNode: LocationNode,
) => {
  const children = locations.filter(
    (location) => location.parentId === currentNode.id,
  );

  const node: LocationTreeNode = {
    title: currentNode.name.toString(),
    value: JSON.stringify({
      id: currentNode.id.toString(),
      name: currentNode.name.toString(),
    }),
    children: children.map((child) => buildLocationTree(locations, child)),
  };

  return node;
};

export const toMappedLocation = (
  locations: LocationNode[],
): MappedLocation[] => {
  console.log('Mapping');
  console.log(locations);
  const result = locations.map((location) => {
    return {
      id: location.id.toString(),
      pId: location.parentId ? location.parentId.toString() : '0',
      rootPId: '0',
      value: JSON.stringify({
        id: location.id,
        name: location.name,
      }),
    };
  });
  console.log(result);
  return result;
};

export default LocationNode;
