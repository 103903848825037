import React from 'react';
import DeleteTableElement from './DeleteTableElement';
import EditTableElement, { MongoObject } from './EditTableElement';
import ViewTableElement from './ViewTableElement';

interface FormElementsProps<T> {
  initialValue?: T;
  readonly?: boolean;
}

interface Props<T extends MongoObject, K> {
  element: T;
  renderElementNameForDelete: () => string;
  refresh: () => void;
  onDelete: (element: T) => Promise<K>;
  onSubmit: (id: string, element: T) => Promise<K>;
  FormElements: React.FC<FormElementsProps<T>>;
  showDeprecationWarning?: boolean;
  newUrl?: string;
}

function TableActionsList<T extends MongoObject, K>({
  element,
  renderElementNameForDelete,
  refresh,
  onDelete,
  onSubmit,
  FormElements,
  showDeprecationWarning,
  newUrl,
}: Props<T, K>) {
  return (
    <>
      <DeleteTableElement
        element={element}
        renderElement={renderElementNameForDelete}
        onDelete={onDelete}
        refresh={refresh}
        key={'delete'}
      />
      <EditTableElement
        element={element}
        onSubmit={onSubmit}
        FormElements={<FormElements initialValue={element} />}
        refresh={refresh}
        key={'edit'}
        showDeprecationWarning={showDeprecationWarning}
        newUrl={newUrl}
      />
      <ViewTableElement
        element={element}
        FormElements={<FormElements initialValue={element} readonly={true} />}
        key={'view'}
      />
    </>
  );
}

export default TableActionsList;
