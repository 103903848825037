import IntegrationConfig from './IntegrationConfig';

interface DeviceConfig {
  _id: string;
  name: string;
  isGroupResidentsByRoomPrefixActive: boolean;
  roomPrefixesToGroupResidents: string[];
  locations: Location[];
  integrationConfig: IntegrationConfig;
  properties: { [key: string]: string };
}

export interface Location {
  id: string;
  name: string;
}

export interface DeviceConfigDto {
  _id: string;
  name: string;
  isGroupResidentsByRoomPrefixActive: boolean;
  roomPrefixesToGroupResidents: string[];
  locations: Location[];
  integrationConfig: string;
  properties: { [key: string]: string };
}

export interface DeviceConfigFormDto {
  _id: string;
  name: string;
  isGroupResidentsByRoomPrefixActive: boolean;
  roomPrefixesToGroupResidents: string[];
  locations: string[];
  integrationConfig: string;
  properties: Property[];
}

interface Property {
  key: string;
  value: string;
}

export const convertToDeviceConfigDto = (
  deviceConfigFormDto: DeviceConfigFormDto,
): DeviceConfigDto => {
  return {
    ...deviceConfigFormDto,
    // TODO: Hacky Solution to get around antd form limitation to only support primitive values and no nested objects
    locations: deviceConfigFormDto.locations.map((location) => {
      return JSON.parse(location);
    }),
    roomPrefixesToGroupResidents:
      deviceConfigFormDto.roomPrefixesToGroupResidents ?? [],
    properties: Object.fromEntries(
      deviceConfigFormDto.properties?.map((property) => [
        property.key,
        property.value,
      ]) ?? [],
    ),
  };
};

export const convertToDeviceConfigFormDto = (
  deviceConfig: DeviceConfig,
): DeviceConfigFormDto => {
  return {
    ...deviceConfig,
    integrationConfig: deviceConfig.integrationConfig?._id,
    locations: deviceConfig.locations.map((location) =>
      JSON.stringify(location),
    ),
    properties: Object.entries(deviceConfig.properties).map((entry) => ({
      key: entry[0],
      value: entry[1],
    })),
  };
};

export default DeviceConfig;
