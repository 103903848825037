import React from 'react';
import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const Page = styled.div`
  flex-direction: column;
  margin-left: 20px;
`;

const PageTitle = styled.h2`
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 1px;
  position: relative;
  margin: 30px 0;

  @media (min-width: 720px) {
    font-size: 35px;
  }
`;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BackButtonContainer = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: 30px;
  margin-right: 24px;
`;

interface Props {
  destination: string;
}

const BackButton: React.FC<Props> = ({ destination }) => {
  return (
    <BackButtonContainer to={destination}>
      <FiArrowLeft />
    </BackButtonContainer>
  );
};

export { Page, PageTitle, TitleBar, BackButton };
