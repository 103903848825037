import React from 'react';
import OrganizationName from 'components/OrganizationName';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { FiLogOut } from 'react-icons/fi';
import VersionTable from './VersionTable';

const ListItem: React.FC<NavLinkProps> = ({ children, ...rest }) => {
  return (
    <Item
      style={({ isActive }: any) =>
        isActive
          ? { backgroundColor: 'hsl(200, 100%, 60%)', color: 'white' }
          : {}
      }
      {...rest}
    >
      {children}
    </Item>
  );
};

const SideBar: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <SideBarContainer>
      <Title>
        <Logo>voize</Logo> <span>Admin Tool</span>
      </Title>
      <OrganizationName />

      <List>
        <ListItem to={`/home`}>Home</ListItem>
        <ListItem to={`/integrations`}>Schnittstellenverwaltung</ListItem>
        <ListItem to={`/deviceconfigs`}>Gerätekonfigurationen</ListItem>
        <ListItem to={`/devices`}>Geräte</ListItem>
        <ListItem to={`/appusers`}>Nutzerverwaltung</ListItem>
        <ListItem to={`/roles`}>Nutzerrollen</ListItem>
      </List>
      <SideBarFooter>
        <SignOutButton
          onClick={() => {
            dispatch({ type: 'PURGE' });
          }}
        >
          Abmelden
          <FiLogOut />
        </SignOutButton>
        <VersionTableContainer>
          <VersionTable />
        </VersionTableContainer>
      </SideBarFooter>
    </SideBarContainer>
  );
};

const VersionTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

const Title = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 30px;

  span {
    margin-left: 12px;
    line-height: 25px;
    text-transform: uppercase;
    color: hsl(200, 100%, 60%);
    font-size: 18px;
    font-weight: 900;
  }
`;

const Logo = styled.div`
  font-weight: 700;
  line-height: 30px;
  font-size: 30px;
  position: relative;

  @media (min-width: 720px) {
    margin-bottom: 0;
    margin-right: unset;
  }
`;

const SignOutButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  background-color: hsl(200, 10%, 90%);

  svg {
    margin-left: 12px;
  }
`;

const SideBarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 300px;
  border-right: 1px solid hsl(200, 10%, 90%);
`;

const SideBarFooter = styled.footer`
  margin-top: auto;
  display: flex;
  flex-direction: column;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px 10px hsla(0, 0%, 0%, 0.01);
`;

const Item = styled(NavLink)`
  border-bottom: 1px solid hsl(200, 10%, 90%);
  font-size: 20px;
  padding: 20px 30px;
  text-decoration: none !important;
  color: black;

  &:hover {
    background-color: hsl(200, 100%, 60%);
    color: white;
  }
`;

export default SideBar;
