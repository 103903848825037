import { Table } from 'antd';
import TableActionsList from 'components/base/table/TableActionsList';
import { BooleanCell } from 'components/base/table/TableCellElements';
import { useAppUserApi } from 'config/api';
import AppUser from 'models/AppUser';
import moment from 'moment';
import React from 'react';
import AppUserForm from './AppUserForm';

interface Props {
  appUsers: AppUser[];
  isLoading: boolean;
  refresh: () => void;
}

const AppUserTable: React.FC<Props> = ({ appUsers, isLoading, refresh }) => {
  const { deleteAppUser, putAppUser } = useAppUserApi();

  const columns = [
    {
      title: 'Kürzel (Username)',
      key: 'username',
      sorter: (a: AppUser, b: AppUser) => a.username.localeCompare(b.username),
      render: (element: AppUser) => element.username,
    },
    {
      title: 'Vorname',
      key: 'firstname',
      render: (element: AppUser) => element.firstname,
    },
    {
      title: 'Nachname',
      key: 'lastname',
      sorter: (a: AppUser, b: AppUser) => a.lastname.localeCompare(b.lastname),
      render: (element: AppUser) => element.lastname,
    },
    {
      title: 'Rolle',
      key: 'role',
      sorter: (a: AppUser, b: AppUser) =>
        a.role.name.localeCompare(b.role.name),
      render: (element: AppUser) => element.role?.name,
    },
    {
      title: 'Registriert',
      key: 'isregistered',
      sorter: (a: AppUser, b: AppUser) => +a.isRegistered - +b.isRegistered,
      // eslint-disable-next-line react/display-name
      render: (element: AppUser) => (
        <BooleanCell value={element.isRegistered} />
      ),
    },
    {
      title: 'Letzte Anmeldung',
      key: 'last-seen-at',
      sorter: (a: AppUser, b: AppUser) => {
        const lastSeen = (x: AppUser) =>
          x.timestamps?.lastSeenAt
            ? new Date(x.timestamps?.lastSeenAt.iso).getTime()
            : 0;
        return lastSeen(b) - lastSeen(a);
      },
      render: (element: AppUser) =>
        element.timestamps?.lastSeenAt
          ? moment(element.timestamps?.lastSeenAt.iso)
              .locale('de')
              .format('dd. DD.MM.YY, HH:mm')
          : '(noch keine Anmeldung)',
    },
    {
      title: "What's new version",
      key: 'whats-new-version',
      sorter: (a: AppUser, b: AppUser) => {
        const version = (x: AppUser) => x.whatsNewVersion ?? 0;
        return version(b) - version(a);
      },
      render: (element: AppUser) =>
        element.whatsNewVersion != null
          ? element.whatsNewVersion.toString()
          : '(noch keine Version)',
    },
    {
      title: 'Aktionen',
      key: 'actions',
      // eslint-disable-next-line react/display-name
      render: (element: AppUser) => (
        <TableActionsList
          element={element}
          renderElementNameForDelete={() =>
            `${element.firstname} ${element.lastname} (${element.username})`
          }
          refresh={refresh}
          onDelete={async () => await deleteAppUser(element._id)}
          onSubmit={async (id: string, element: AppUser) =>
            await putAppUser(id, element)
          }
          FormElements={AppUserForm}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey={(element: AppUser) => element._id}
      dataSource={appUsers}
      loading={isLoading}
      pagination={false}
    />
  );
};

export default AppUserTable;
