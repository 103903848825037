import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import TableActionsList from 'components/base/table/TableActionsList';
import { useDeviceApi } from 'config/api';
import Device, { convertToDeviceDto, DeviceDto } from 'models/Device';
import moment from 'moment';
import React from 'react';
import DeviceForm from './DeviceForm';

import 'moment/locale/de';
import _ from 'lodash';
import DeviceConfig from 'models/DeviceConfigs';

interface Props {
  devices: Device[];
  refresh: () => void;
  isLoading: boolean;
}

const DevicesTable: React.FC<Props> = ({ devices, refresh, isLoading }) => {
  const { deleteDevice, putDevice } = useDeviceApi();

  const getAppVersionString = (device: Device) =>
    device.info
      ? `[${device.info.appBuildNumber}] ${device.info.appVersion}`
      : '(Gerät noch nicht registriert)';

  const getAndroidDeviceName = (device: Device) =>
    device.info ? device.info.deviceName : '(Gerät noch nicht registriert)';

  const getModelString = (device: Device) =>
    device.info
      ? `${device.info.brand} ${device.info.device} (
  ${device.info.deviceModel})`
      : '(Gerät noch nicht registriert)';

  const getDeviceConfigName = (deviceConfig: DeviceConfig | null) =>
    deviceConfig ? deviceConfig.name : '(keine Konfiguration)';

  const getDeviceConfigId = (deviceConfig: DeviceConfig | null) =>
    deviceConfig ? deviceConfig._id : 'none';

  const columns: ColumnType<Device>[] = [
    {
      title: 'Name',
      sorter: (a: Device, b: Device) => a.name.localeCompare(b.name),
      key: 'name',
      render: (device: Device) => device.name,
      filters: _.uniq(devices.map((d) => d.name)).map((deviceName) => ({
        text: deviceName,
        value: deviceName,
      })),
      filterSearch: true,
      onFilter: (deviceName, device) => device.name === deviceName,
    },
    {
      title: 'Android Gerätename',
      sorter: (a: Device, b: Device) =>
        getAndroidDeviceName(a).localeCompare(getAndroidDeviceName(b)),
      key: 'name',
      render: (device: Device) => getAndroidDeviceName(device),
      filters: _.uniq(devices.map(getAndroidDeviceName)).map(
        (androidDeviceName) => ({
          text: androidDeviceName,
          value: androidDeviceName,
        }),
      ),
      filterSearch: true,
      onFilter: (androidDeviceName, device) =>
        getAndroidDeviceName(device) === androidDeviceName,
    },
    {
      title: 'Geräte ID',
      sorter: (a: Device, b: Device) => a._id.localeCompare(b._id),
      key: 'id',
      render: (device: Device) => device._id,
      filters: devices.map((device) => ({
        text: device._id,
        value: device._id,
      })),
      filterSearch: true,
      onFilter: (deviceId, device) => device._id === deviceId,
    },
    {
      title: 'Geräte Model',
      key: 'model',
      sorter: (a: Device, b: Device) =>
        getModelString(a).localeCompare(getModelString(b)),
      render: (device: Device) => getModelString(device),
      filters: _.uniq(devices.map(getModelString)).map((model) => ({
        text: model,
        value: model,
      })),
      filterSearch: true,
      onFilter: (model, device) => getModelString(device) === model,
    },
    {
      title: 'Geräte Konfiguration',
      sorter: (a: Device, b: Device) =>
        getDeviceConfigName(a.deviceConfig).localeCompare(
          getDeviceConfigName(b.deviceConfig),
        ),
      key: 'deviceconfig',
      render: (device: Device) => getDeviceConfigName(device.deviceConfig),
      filters: _.uniqBy(
        devices.map((device) => device.deviceConfig),
        getDeviceConfigId,
      ).map((deviceConfig) => ({
        text: getDeviceConfigName(deviceConfig),
        value: getDeviceConfigId(deviceConfig),
      })),
      filterSearch: true,
      onFilter: (deviceConfigId, device) =>
        getDeviceConfigId(device.deviceConfig) === deviceConfigId,
    },
    {
      title: 'voize App Version',
      key: 'app-version',
      sorter: (a: Device, b: Device) =>
        getAppVersionString(a).localeCompare(getAppVersionString(b)),
      render: (device: Device) => getAppVersionString(device),
      filters: _.uniq(devices.map(getAppVersionString)).map((version) => ({
        text: version,
        value: version,
      })),
      filterSearch: true,
      onFilter: (version, device) => getAppVersionString(device) === version,
    },
    {
      title: 'Letztes Signal',
      key: 'last-seen-at',
      defaultSortOrder: 'ascend',
      sorter: (a: Device, b: Device) => {
        const aTime = a.lastSeenAt ? new Date(a.lastSeenAt).getTime() : 0;
        const bTime = b.lastSeenAt ? new Date(b.lastSeenAt).getTime() : 0;
        return bTime - aTime;
      },
      render: (device: Device) =>
        device.lastSeenAt
          ? moment(device.lastSeenAt).locale('de').format('dd. DD.MM.YY, HH:mm')
          : '(noch kein Signal)',
    },
    {
      title: 'Aktionen',
      key: 'actions',
      // eslint-disable-next-line react/display-name
      render: (element: Device) => (
        <TableActionsList
          element={convertToDeviceDto(element)}
          renderElementNameForDelete={() => `${element.name} (${element._id})`}
          refresh={refresh}
          onDelete={async () => await deleteDevice(element._id)}
          onSubmit={async (id: string, element: DeviceDto) =>
            await putDevice(id, element)
          }
          FormElements={DeviceForm}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey={(device) => device._id}
      dataSource={devices}
      loading={isLoading}
      pagination={false}
    />
  );
};

export default DevicesTable;
