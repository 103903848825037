import { useCallback, useEffect, useState } from 'react';

export function useFetchedData<T>(fetch: () => Promise<T>, defaultValue: T) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<T>(defaultValue);
  const [error, setError] = useState<string | null>(null);

  const executeFetch = useCallback(async () => {
    try {
      const data = await fetch();
      setData(data);
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError('Der Server ist momentan nicht erreichbar.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [fetch]);

  useEffect(() => {
    executeFetch();
  }, [executeFetch]);

  return { isLoading, error, data, refresh: executeFetch };
}
