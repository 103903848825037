import { PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import CreateElementModal from 'components/base/table/CreateElementModal';
import { Page, PageTitle, TitleBar } from 'components/layout/Page';
import { useRoleApi } from 'config/api';
import { useFetchedData } from 'hooks/useFetchedData';
import useIsDeprecated from 'hooks/useIsDeprecated';
import Role from 'models/Role';
import React, { useState } from 'react';
import RolesForm from './RolesForm';
import RolesTable from './RolesTable';

const RolesManagement: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { getRoles, postRole } = useRoleApi();
  const { data: roles, refresh, isLoading } = useFetchedData(getRoles, []);

  const { isDeprecated } = useIsDeprecated();

  return (
    <Page>
      <TitleBar>
        <PageTitle>Rollen Verwaltung</PageTitle>

        {isDeprecated ? (
          <Popconfirm
            title={
              <>
                Das Admin Tool wurde nach{' '}
                <a
                  href={'https://admin.voize.de/'}
                  style={{
                    color: '#1890ff',
                    textDecoration: 'underline',
                  }}
                >
                  admin.voize.de
                </a>{' '}
                umgezogen. Möchtest du diese Aktion trotzdem hier durchführen?
              </>
            }
            okText="Ja"
            cancelText="Abbrechen"
            onConfirm={() => setIsModalVisible(true)}
          >
            <Button type={'primary'} icon={<PlusOutlined />}>
              Neu
            </Button>
          </Popconfirm>
        ) : (
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            Neu
          </Button>
        )}

        <CreateElementModal
          isModalVisible={isModalVisible}
          onSave={async (values: Role) => {
            await postRole(values);
            refresh();
            setIsModalVisible(false);
          }}
          onCancel={() => setIsModalVisible(false)}
          FormElements={<RolesForm />}
        />
      </TitleBar>
      <RolesTable roles={roles} refresh={refresh} isLoading={isLoading} />
    </Page>
  );
};

export default RolesManagement;
