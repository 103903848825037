import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, Tag, message } from 'antd';
import AntIconWrapper from 'components/base/AntIconWrapper';
import TableActionsList from 'components/base/table/TableActionsList';
import { useDeviceConfigApi } from 'config/api';
import useIsDeprecated from 'hooks/useIsDeprecated';
import DeviceConfig, {
  DeviceConfigFormDto,
  convertToDeviceConfigDto,
  convertToDeviceConfigFormDto,
} from 'models/DeviceConfigs';
import React from 'react';
import DeviceConfigForm from './DeviceConfigForm';

interface Props {
  deviceConfigs: DeviceConfig[];
  isLoading: boolean;
  refresh: () => void;
}

const DeviceConfigsTable: React.FC<Props> = ({
  deviceConfigs,
  refresh,
  isLoading,
}) => {
  const { deleteDeviceConfig, putDeviceConfig, migrateDeviceConfig } =
    useDeviceConfigApi();

  const { isDeprecated } = useIsDeprecated();

  const columns = [
    {
      title: 'Name',
      sorter: true,
      key: 'name',
      render: (element: DeviceConfig) => element.name,
    },
    {
      title: 'Bereiche',
      sorter: true,
      key: 'areas',
      render: (element: DeviceConfig) =>
        element.locations.map((location) => (
          <Tag key={location.id}>
            {location.name} ({location.id})
          </Tag>
        )),
    },
    {
      title: 'Schnittstelle',
      sorter: true,
      key: 'integrationconfig',
      render: (element: DeviceConfig) =>
        element.integrationConfig?.name ?? '(keine Schnittstelle gesetzt)',
    },
    {
      title: 'Geräteeigenschaften',
      sorter: true,
      key: 'deviceproperties',
      render: (element: DeviceConfig) =>
        Object.entries(element.properties).map(([key, value]) => (
          <Tag color="blue" key={key}>{`${key}:${
            value.length > 100 ? `${value.substring(0, 100)}...` : value
          }`}</Tag>
        )),
    },
    {
      title: 'Aktionen',
      key: 'actions',
      // eslint-disable-next-line react/display-name
      render: (element: DeviceConfig) => (
        <>
          <TableActionsList
            element={convertToDeviceConfigFormDto(element)}
            renderElementNameForDelete={() =>
              `${element.name} (${element._id})`
            }
            refresh={refresh}
            onDelete={async () => await deleteDeviceConfig(element._id)}
            onSubmit={async (id: string, element: DeviceConfigFormDto) => {
              return await putDeviceConfig(
                id,
                convertToDeviceConfigDto(element),
              );
            }}
            FormElements={DeviceConfigForm}
            showDeprecationWarning={isDeprecated}
            newUrl="https://admin.voize.de/management/-/senior-care-device-configurations"
          />
          {element.properties['ADMIN_TOOL_MIGRATION_CONFIG'] === undefined ? (
            <Popconfirm
              title="Are you sure to migrate this device config?"
              onConfirm={async () => {
                try {
                  await migrateDeviceConfig(element._id);
                } catch (error) {
                  message.error(
                    `An error occurred while migrating device config ${element.name}`,
                  );
                }
              }}
              okText="Yes"
            >
              <Button type="link" size="small">
                <AntIconWrapper>
                  <CloudUploadOutlined />
                </AntIconWrapper>
              </Button>
            </Popconfirm>
          ) : (
            <Tag color="blue">Migrated</Tag>
          )}
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey={(element) => element._id}
      dataSource={deviceConfigs}
      loading={isLoading}
      pagination={false}
      onRow={(deviceConfig) => {
        return {
          style:
            deviceConfig.properties['ADMIN_TOOL_MIGRATION_CONFIG'] === undefined
              ? {}
              : {
                  background: '#f0f0f0',
                  opacity: 0.4,
                },
        };
      }}
    />
  );
};

export default DeviceConfigsTable;
