import {
  CheckOutlined,
  CloseOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  TreeSelect,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { useIntegrationConfigApi, useLocationsApi } from 'config/api';
import { useFetchedData } from 'hooks/useFetchedData';
import useIsDeprecated from 'hooks/useIsDeprecated';
import _ from 'lodash';
import { DeviceConfigFormDto } from 'models/DeviceConfigs';
import { LocationTreeNode, toLocationTreeNode } from 'models/Location';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

interface Props {
  initialValue?: DeviceConfigFormDto;
  readonly?: boolean;
}

const { SHOW_CHILD, SHOW_PARENT } = TreeSelect;

const DeviceConfigForm: React.FC<Props> = ({
  initialValue,
  readonly = false,
}) => {
  const { getIntegrationConfigs } = useIntegrationConfigApi();

  const { data: allIntegrationConfigs } = useFetchedData(
    getIntegrationConfigs,
    [],
  );

  const [isManualLocationsEnabled, setIsManualLocationsEnabled] =
    useState(false);
  const [showOnlyParentNode, setshowOnlyParentNode] = useState(true);

  const [integrationConfigId, setintegrationConfigId] = useState<
    string | undefined
  >(initialValue?.integrationConfig);

  const { isDeprecated } = useIsDeprecated();

  const { getLocations } = useLocationsApi();
  const { data: locations } = useFetchedData(
    useCallback(
      () =>
        integrationConfigId
          ? getLocations(integrationConfigId)
          : Promise.resolve([]),
      [integrationConfigId, getLocations],
    ),
    [],
  );

  const [mappedLocations, setMappedLocations] = useState<LocationTreeNode[]>(
    [],
  );

  useEffect(() => {
    setMappedLocations(toLocationTreeNode(locations));
  }, [locations]);

  return (
    <>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Geben Sie einen Namen ein.' }]}
        hasFeedback
        initialValue={initialValue?.name}
      >
        <Input readOnly={readonly} />
      </Form.Item>

      <Form.Item
        label="Schnittstelle"
        name="integrationConfig"
        rules={[{ required: true, message: 'Wählen Sie eine Schnittstelle.' }]}
        hasFeedback
        initialValue={initialValue?.integrationConfig}
      >
        <Select
          placeholder="Wählen Sie eine Schnittstelle."
          onChange={(value: string) => setintegrationConfigId(value)}
        >
          {_.sortBy(allIntegrationConfigs, 'name').map((element) => (
            <Select.Option key={element._id} value={element._id}>
              {element.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <ElementGroup>
        <Divider plain>Bereiche</Divider>
        <Row style={{ marginBottom: '12px' }}>
          <Radio.Group
            onChange={() =>
              setIsManualLocationsEnabled(!isManualLocationsEnabled)
            }
            value={isManualLocationsEnabled}
          >
            <Radio value={false}>Aus geladenen</Radio>
            <Radio value={true}>Manuell</Radio>
          </Radio.Group>
        </Row>
        {isManualLocationsEnabled ? (
          <Form.Item
            label="Wohnbereiche"
            name="locations"
            tooltip="Wählen Sie einen Vivendi Bereich. Die Bewohner:innen aus diesem Bereich werden in der voize App angezeigt."
            rules={[{ required: true, message: 'Wählen Sie einen Bereich.' }]}
            hasFeedback
            initialValue={initialValue?.locations}
          >
            <Select mode="tags" />
          </Form.Item>
        ) : (
          <>
            {integrationConfigId ? (
              <>
                <TreeModeToggleContainer>
                  <TreeModeToggleLabel>
                    Baum Modus - Vaterknoten (Fasst mehrere Kindknoten zu
                    Vaterknoten zusammen)
                  </TreeModeToggleLabel>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={showOnlyParentNode}
                    onChange={() => setshowOnlyParentNode(!showOnlyParentNode)}
                  />
                </TreeModeToggleContainer>

                <Form.Item
                  label="Wohnbereiche"
                  name="locations"
                  tooltip="Wählen Sie einen Vivendi Bereich. Die Bewohner:innen aus diesem Bereich werden in der voize App angezeigt."
                  rules={[
                    { required: true, message: 'Wählen Sie einen Bereich.' },
                  ]}
                  hasFeedback
                  initialValue={initialValue?.locations}
                >
                  <TreeSelect
                    treeData={mappedLocations}
                    treeCheckable={true}
                    showCheckedStrategy={
                      showOnlyParentNode ? SHOW_PARENT : SHOW_CHILD
                    }
                    placeholder="Bitte wählen Sie einen Bereich."
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </>
            ) : (
              <Text type="secondary">
                Wähle eine Schnittstelle aus um mögliche Bereiche zu laden oder
                nutze die manuelle Eingabe.
              </Text>
            )}
          </>
        )}
      </ElementGroup>

      <ElementGroup>
        <Divider plain>Custom Configs</Divider>
        <Form.List name="properties" initialValue={initialValue?.properties}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space key={field.key} align="baseline">
                  <Form.Item
                    {...field}
                    style={{ width: 500 }}
                    label="Key"
                    name={[field.name, 'key']}
                    rules={[{ required: true, message: 'Fehlender Key' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label="Value"
                    name={[field.name, 'value']}
                    rules={[{ required: true, message: 'Fehlender Value' }]}
                  >
                    <Input />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Property Hinzufügen
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </ElementGroup>

      <ElementGroup>
        <Divider plain>Bewohnergruppierung nach Zimmername</Divider>

        <Form.Item
          label="Gruppierung von Bewohner:innen nach Zimmername REGEX"
          name="isGroupResidentsByRoomPrefixActive"
          tooltip="Mit dieser Option können Bewohner:innen in der voize App anhand von den Zimmernamen mittels REGEX gruppiert werden. Die Konfiguration bitte mit Ihrem voize-Ansprechpartner abstimmen."
          initialValue={
            initialValue?.isGroupResidentsByRoomPrefixActive ?? false
          }
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={
              initialValue?.isGroupResidentsByRoomPrefixActive ?? false
            }
          />
        </Form.Item>

        <Form.List
          name="roomPrefixesToGroupResidents"
          initialValue={initialValue?.roomPrefixesToGroupResidents}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field) => (
                <Form.Item
                  label="Zimmername Gruppen REGEX String"
                  required={false}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: false,
                        message: 'Geben Sie einen Bereichsgruppen String ein.',
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="Zimmer Gruppierungs-REGEX"
                      style={{ width: '60%' }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  Gruppierungs String hinzufügen
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </ElementGroup>

      {isDeprecated && (
        <Alert
          type="error"
          message="Das Admin Tool ist veraltet. Bitte nutze das neue Admin Tool um Gerätekonfigurationen zu ändern."
        />
      )}
    </>
  );
};

const ElementGroup = styled.div``;

const TreeModeToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const TreeModeToggleLabel = styled.div`
  padding-right: 20px;
`;

export default DeviceConfigForm;
