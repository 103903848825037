import { useDeviceConfigApi } from 'config/api';
import { useMemo } from 'react';
import { useFetchedData } from './useFetchedData';

const useIsDeprecated = () => {
  const { getDeviceConfigs } = useDeviceConfigApi();

  const { data: deviceConfigs, isLoading } = useFetchedData(
    getDeviceConfigs,
    [],
  );

  const isDeprecated = useMemo(() => {
    return deviceConfigs.some(
      (config) =>
        config.properties['ADMIN_TOOL_MIGRATION_CONFIG'] !== undefined,
    );
  }, [deviceConfigs]);

  return {
    isDeprecated,
    isLoading,
  };
};

export default useIsDeprecated;
