import React, { useEffect, useRef, useState } from 'react';
import OQRCode from 'qrcode';

interface Props {
  content: string;
}

const QRCode: React.FC<Props> = ({ content }) => {
  const canvas = useRef(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    OQRCode.toCanvas(canvas.current, content, (error) => {
      if (error) {
        setError(error.message);
      }
    });
  }, [content]);

  if (error) return <span>{error}</span>;

  return <canvas ref={canvas}></canvas>;
};

export default QRCode;
