import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAccessTokenExpires, useIsAuthenticated } from 'state/authReducer';
import styled from 'styled-components';
import '../assets/fonts/fonts.css';
import GlobalStyle from '../style/GlobalStyle';
import DeprecatedWarning from './DeprecatedWarning';
import AppUserManagement from './app-users/AppUserManagement';
import SignIn from './auth/SignIn';
import DeviceConfigManagement from './device_config_management/DeviceConfigManagement';
import DeviceManagement from './device_management/DeviceManagement';
import Home from './home/Home';
import IntegrationManagement from './integration-management/IntegrationManagement';
import SideBar from './layout/SideBar';
import RolesManagement from './roles-management/RolesManagement';

const App: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const authExpiryDate = useAccessTokenExpires();
  const dispatch = useDispatch();

  const logoutIfExpired = useCallback(() => {
    if (isAuthenticated && authExpiryDate && moment().isAfter(authExpiryDate)) {
      dispatch({ type: 'PURGE' });
    }
  }, [authExpiryDate, dispatch, isAuthenticated]);

  useEffect(() => {
    logoutIfExpired();
    const interval = setInterval(logoutIfExpired, 10 * 1000);
    return () => clearInterval(interval);
  }, [logoutIfExpired]);

  return (
    <Container>
      <GlobalStyle />
      {isAuthenticated ? (
        <BodyContainer>
          <SideBar />
          <Content>
            <DeprecatedWarning />
            <Routes>
              <Route path="/home" Component={Home} />
              <Route path="/roles" Component={RolesManagement} />
              <Route path="/deviceconfigs" Component={DeviceConfigManagement} />
              <Route path="/devices" Component={DeviceManagement} />
              <Route path="/integrations" Component={IntegrationManagement} />
              <Route path="/appusers" Component={AppUserManagement} />
              <Route path="/" element={<Navigate to="/home" />} />
            </Routes>
          </Content>
        </BodyContainer>
      ) : (
        <SignIn />
      )}
    </Container>
  );
};

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px;
  overflow-y: scroll;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`;

export default App;
