import React from 'react';
import { Page, PageTitle } from 'components/layout/Page';
import { Card, Typography } from 'antd';
import Meta from 'antd/es/card/Meta';
import {
  AiOutlineCode,
  AiOutlineCustomerService,
  MdTextFields,
} from 'react-icons/all';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { voizeBlue } from 'style/colors';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const redirectTo = (location: string) => () => {
    navigate(location);
  };

  return (
    <Page>
      <PageTitle>Home</PageTitle>
      <Typography.Title level={4}>Favouriten</Typography.Title>
      <FavouritesContainer>
        <FavouritesCard hoverable onClick={redirectTo('/devices')}>
          <Meta
            avatar={<AiOutlineCustomerService />}
            title="Neues Handy Hinzufügen"
          />
        </FavouritesCard>
        <FavouritesCard hoverable onClick={redirectTo('/appusers')}>
          <Meta avatar={<MdTextFields />} title="Neuen Nutzer Registrieren" />
        </FavouritesCard>
      </FavouritesContainer>
      <Typography.Title level={4}>Weitere Funktionen</Typography.Title>
      <CardsContainer>
        <Card
          style={{ width: 300, margin: 16 }}
          hoverable
          onClick={redirectTo('/deviceconfigs')}
        >
          <Meta
            avatar={<AiOutlineCustomerService />}
            title="Geräteeinstellungen"
            description="Verwalten Sie die voize Geräte Profile."
          />
        </Card>
        <Card
          style={{ width: 300, margin: 16 }}
          hoverable
          onClick={redirectTo('/integrations')}
        >
          <Meta
            avatar={<MdTextFields />}
            title="Schnittstellen Einstellungen"
            description="Verwalten Sie die Schnittstelleneinstellungen zum Vivendi VX Proxy."
          />
        </Card>
        <Card
          style={{ width: 300, margin: 16 }}
          hoverable
          onClick={redirectTo('/devices')}
        >
          <Meta
            avatar={<MdTextFields />}
            title="Geräteverwaltung"
            description="Verwalten Sie die Smartphones und deren Einstellungsvorlagen."
          />
        </Card>
        <Card
          style={{ width: 300, margin: 16 }}
          hoverable
          onClick={redirectTo('/appusers')}
        >
          <Meta
            avatar={<AiOutlineCode />}
            title="Nutzerverwaltung"
            description="Verwaltung der Nutzeraccounts."
          />
        </Card>
        <Card
          style={{ width: 300, margin: 16 }}
          hoverable
          onClick={redirectTo('models')}
        >
          <Meta
            avatar={<AiOutlineCode />}
            title="Nutzerrollen"
            description="Verwaltung von Rollen und Berechtigungen."
          />
        </Card>
      </CardsContainer>
    </Page>
  );
};

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 30px;
`;

const FavouritesContainer = styled(CardsContainer)``;
const FavouritesCard = styled(Card)`
  width: 300px;
  margin: 16px;
  text-decoration-color: ${voizeBlue};
  text-decoration-line: underline;
  text-decoration-thickness: 7px;
  border-radius: 5px;
`;

export default Home;
