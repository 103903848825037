import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
  }

  #root {
    display: flex;
  }

  body {
    font-family: 'Jost*', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }

  button, a {
    -webkit-tap-highlight-color: transparent;
  }

  button {
    font: inherit;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  a {
    font-style: normal;
    color: inherit;
    text-decoration: none;
  }

  input, textarea {
    font: inherit;
    border: none;
    padding: 10px 18px;
    outline: none;
  }

  textarea {
    padding: 18px;
  }
`;

export default GlobalStyle;
