import { Form, Input, Select } from 'antd';
import { useDeviceConfigApi } from 'config/api';
import { useFetchedData } from 'hooks/useFetchedData';
import _ from 'lodash';
import { DeviceDto } from 'models/Device';
import React from 'react';

interface Props {
  initialValue?: DeviceDto;
  readonly?: boolean;
}

const DeviceForm: React.FC<Props> = ({ initialValue, readonly = false }) => {
  const { getDeviceConfigs } = useDeviceConfigApi();
  const { data: deviceConfigs } = useFetchedData(getDeviceConfigs, []);

  return (
    <>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Geben Sie einen Namen ein.' }]}
        hasFeedback
        initialValue={initialValue?.name}
      >
        <Input readOnly={readonly} />
      </Form.Item>

      <Form.Item
        label="Gerätekonfiguration"
        name="deviceConfig"
        rules={[{ required: true, message: 'Wählen Sie eine DeviceConfig' }]}
        hasFeedback
        initialValue={initialValue?.deviceConfig}
      >
        <Select placeholder="Wählen Sie ein Element.">
          {_.sortBy(deviceConfigs, 'name').map((element) => (
            <Select.Option key={element._id} value={element._id}>
              {element.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default DeviceForm;
