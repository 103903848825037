import { Alert, Button, Modal, Popconfirm } from 'antd';
import Form from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import AntIconWrapper from '../AntIconWrapper';

export interface MongoObject {
  _id: string;
}
interface Props<T extends MongoObject, K> {
  element: T;
  onSubmit: (id: string, element: T) => Promise<K>;
  refresh: () => void;
  FormElements: React.ReactNode;
  showDeprecationWarning?: boolean;
  newUrl?: string;
}

export function EditTableElement<T extends MongoObject, K>({
  element,
  onSubmit,
  refresh,
  FormElements,
  showDeprecationWarning,
  newUrl,
}: Props<T, K>) {
  const [isModalVisible, setIsModalVisble] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleSave = async (values: T) => {
    try {
      await onSubmit(element._id, values);
      setIsModalVisble(false);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      refresh();
    }
  };

  const handleCancel = () => {
    setIsModalVisble(false);
  };

  const formId = 'edit-form';

  return (
    <>
      {isModalVisible && (
        <Modal
          title="Element Bearbeiten"
          width={1000}
          visible={isModalVisible}
          footer={[
            <Button
              form={formId}
              key="reset"
              htmlType="reset"
              onClick={handleCancel}
            >
              Abbrechen
            </Button>,
            <Button type="primary" form={formId} key="submit" htmlType="submit">
              Speichern
            </Button>,
          ]}
          onCancel={handleCancel}
        >
          <Form id={formId} name="basic" onFinish={handleSave}>
            {FormElements}
          </Form>
          {error && (
            <Alert
              message="Error"
              description={error.message}
              type="error"
              showIcon
            />
          )}
        </Modal>
      )}
      {showDeprecationWarning ? (
        <Popconfirm
          title={
            <>
              Das Admin Tool wurde nach{' '}
              <a
                href={newUrl ?? 'https://admin.voize.de/'}
                style={{
                  color: '#1890ff',
                  textDecoration: 'underline',
                }}
              >
                admin.voize.de
              </a>{' '}
              umgezogen. Möchtest du diese Konfiguration trotzdem hier
              bearbeiten?
            </>
          }
          okText="Ja"
          cancelText="Abbrechen"
          onConfirm={() => setIsModalVisble(true)}
        >
          <Button type="link" size="small">
            <AntIconWrapper>
              <AiOutlineEdit />
            </AntIconWrapper>
          </Button>
        </Popconfirm>
      ) : (
        <Button type="link" size="small" onClick={() => setIsModalVisble(true)}>
          <AntIconWrapper>
            <AiOutlineEdit />
          </AntIconWrapper>
        </Button>
      )}
    </>
  );
}

export default EditTableElement;
