import React from 'react';
import { Form, Input, Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import { IntegrationConfigDto } from 'models/IntegrationConfig';

interface Props {
  initialValue?: IntegrationConfigDto;
  readonly?: boolean;
}

const IntegrationForm: React.FC<Props> = ({
  readonly = false,
  initialValue,
}) => {
  return (
    <>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Geben Sie einen Namen ein.' }]}
        hasFeedback
        initialValue={initialValue?.name}
      >
        <Input readOnly={readonly} />
      </Form.Item>
      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: true, message: 'Please input your username!' }]}
        hasFeedback
        initialValue={initialValue?.type}
      >
        <Select
          placeholder="Wählen Sie einen Schnittstellentyp aus."
          allowClear
          aria-readonly={readonly}
        >
          <Option value="CONNEXT_VIVENDI">Connext Vivendi</Option>
          <Option value="OTHER">Other</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="API URL"
        tooltip="z.B. URL zum Vivendi Proxy mit /api am Ende z.B. https://vivapp02.vivendi.de/api"
        name="baseUrl"
        rules={[{ required: true, message: 'Geben Sie die Api URL ein!' }]}
        hasFeedback
        initialValue={initialValue?.baseUrl}
      >
        <Input readOnly={readonly} />
      </Form.Item>
      <Form.Item
        label="Sync User Kürzel"
        name="syncUsername"
        tooltip="Nutzeraccount mit Leseberechtigung für die Bereiche, in denen die voize App eingesetzt wird."
        rules={[
          {
            required: true,
            message: 'Geben Sie das Vivendi Kürzel des Sync Users ein!',
          },
        ]}
        hasFeedback
        initialValue={initialValue?.syncUsername}
      >
        <Input readOnly={readonly} />
      </Form.Item>
      <Form.Item
        label="Sync User Passwort"
        name="syncPassword"
        rules={[
          {
            required: true,
            message: 'Geben Sie das Passwort des Sync Users ein!',
          },
        ]}
        initialValue={initialValue?.syncPassword}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
    </>
  );
};

export default IntegrationForm;
