import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import React from 'react';

interface BooleanCellProps {
  value: boolean;
}

export const BooleanCell: React.FC<BooleanCellProps> = ({ value }) => {
  return value ? (
    <CheckCircleTwoTone twoToneColor="#52c41a" />
  ) : (
    <CloseCircleTwoTone twoToneColor="#eb2f48" />
  );
};

interface NullableBooleanCellProps {
  value: boolean | null;
}

export const NullableBooleanCell: React.FC<NullableBooleanCellProps> = ({
  value,
}) => {
  return value == null ? (
    <CloseCircleTwoTone twoToneColor="#b0b0b0" />
  ) : (
    <BooleanCell value={value} />
  );
};
