import { useOrganizationApi } from 'config/api';
import { useFetchedData } from 'hooks/useFetchedData';
import React from 'react';
import 'react-activity/dist/react-activity.css';
import styled from 'styled-components';

const OrganizationName: React.FC = () => {
  const { getOrganization } = useOrganizationApi();
  const { data: organization } = useFetchedData(getOrganization, null);

  return (
    <Container>
      <div>{organization?.name}</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  color: hsl(200, 100%, 60%);
  font-size: 22px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 20px 30px 20px;
  border-bottom: 1px solid hsl(200, 10%, 90%);
`;

export default OrganizationName;
