import { AnyAction as ReduxAnyAction } from 'redux';
import { useSelector } from 'react-redux';
import { RootState } from './rootReducer';

export interface State {
  token: string | null;
  expires: Date | null;
}

export const initialState = {
  token: null,
  expires: null,
};

function authReducer(state: State = initialState, reduxAction: ReduxAnyAction) {
  switch (reduxAction.type) {
    case 'LOGIN':
      return {
        ...state,
        token: reduxAction.token,
        expires: reduxAction.expires,
      };
    default:
      return state;
  }
}

export function useIsAuthenticated() {
  return useSelector((state: RootState) => !!state.auth.token);
}

export function useAccessTokenExpires() {
  return useSelector((state: RootState) => state.auth.expires);
}

export function useAccessToken() {
  return useSelector((state: RootState) => state.auth.token);
}

export default authReducer;
