export const readDevicesScope = 'read:devices';
export const writeDevicesScope = 'write:devices';
export const deleteDevicesScope = 'delete:devices';
export const readDeviceSecretsScope = 'read:device-secrets';

export const readDeviceConfigsScope = 'read:device-configs';
export const writeDeviceConfigsScope = 'write:device-configs';
export const deleteDeviceConfigsScope = 'delete:device-configs';

export const readIntegrationConfigsScope = 'read:integration-configs';
export const writeIntegrationConfigsScope = 'write:integration-configs';
export const deleteIntegrationConfigsScope = 'delete:integration-configs';

export const readAppUsersScope = 'read:app-users';
export const writeAppUsersScope = 'write:app-users';
export const deleteAppUsersScope = 'delete:app-users';

export const readRolesScope = 'read:roles';
export const writeRolesScope = 'write:roles';
export const deleteRolesScope = 'delete:roles';

export const readOrganizationScope = 'read:organization';

export const allScopes = [
  readDevicesScope,
  writeDevicesScope,
  deleteDevicesScope,
  readDeviceSecretsScope,
  readDeviceConfigsScope,
  writeDeviceConfigsScope,
  deleteDeviceConfigsScope,
  readIntegrationConfigsScope,
  writeIntegrationConfigsScope,
  deleteIntegrationConfigsScope,
  readAppUsersScope,
  writeAppUsersScope,
  deleteAppUsersScope,
  readRolesScope,
  writeRolesScope,
  deleteRolesScope,
  readOrganizationScope,
];
