import { CloudUploadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, message } from 'antd';
import CreateElementModal from 'components/base/table/CreateElementModal';
import { Page, PageTitle, TitleBar } from 'components/layout/Page';
import { useDeviceConfigApi } from 'config/api';
import { useFetchedData } from 'hooks/useFetchedData';
import useIsDeprecated from 'hooks/useIsDeprecated';
import {
  DeviceConfigFormDto,
  convertToDeviceConfigDto,
} from 'models/DeviceConfigs';
import React, { useState } from 'react';
import DeviceConfigForm from './DeviceConfigForm';
import DeviceConfigsTable from './DeviceConfigsTable';

const DeviceConfigManagement: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { getDeviceConfigs, postDeviceConfig, migrateDeviceConfig } =
    useDeviceConfigApi();
  const {
    data: deviceConfigs,
    isLoading,
    error,
    refresh,
  } = useFetchedData(getDeviceConfigs, []);

  const { isDeprecated } = useIsDeprecated();

  return (
    <Page>
      <TitleBar>
        <PageTitle>Gerätekonfigurationsverwaltung</PageTitle>
        <div>Anzahl der Gerätekonfigurationen: {deviceConfigs.length}</div>

        <Popconfirm
          title="Are you sure to migrate all device configs?"
          onConfirm={async () => {
            for (const element of deviceConfigs) {
              try {
                await migrateDeviceConfig(element._id);
              } catch (e) {
                message.error(
                  `An error occurred while migrating device config ${element.name}`,
                );
              }
            }
          }}
          okText="Yes"
        >
          <Button type={'primary'} icon={<CloudUploadOutlined />}>
            Migrate All Device Configs
          </Button>
        </Popconfirm>

        {isDeprecated ? (
          <Popconfirm
            title={
              <>
                Das Admin Tool wurde nach{' '}
                <a
                  href={
                    'https://admin.voize.de/management/-/device-configurations'
                  }
                  style={{
                    color: '#1890ff',
                    textDecoration: 'underline',
                  }}
                >
                  admin.voize.de
                </a>{' '}
                umgezogen. Möchtest du diese Konfiguration trotzdem hier
                bearbeiten?
              </>
            }
            okText="Ja"
            cancelText="Abbrechen"
            onConfirm={() => setIsModalVisible(true)}
          >
            <Button type={'primary'} icon={<PlusOutlined />}>
              Neu
            </Button>
          </Popconfirm>
        ) : (
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            Neu
          </Button>
        )}

        <CreateElementModal
          isModalVisible={isModalVisible}
          onSave={async (values: DeviceConfigFormDto) => {
            await postDeviceConfig(convertToDeviceConfigDto(values));
            await refresh();
            setIsModalVisible(false);
          }}
          onCancel={() => setIsModalVisible(false)}
          FormElements={<DeviceConfigForm />}
        />
      </TitleBar>
      {error && <div>{error}</div>}
      <DeviceConfigsTable
        deviceConfigs={deviceConfigs}
        refresh={refresh}
        isLoading={isLoading}
      />
    </Page>
  );
};

export default DeviceConfigManagement;
