import { Page, PageTitle, TitleBar } from 'components/layout/Page';
import { useAppUserApi } from 'config/api';
import { useFetchedData } from 'hooks/useFetchedData';
import React from 'react';
import AppUserTable from './AppUserTable';

const AppUserManagement: React.FC = () => {
  const { getAppUsers } = useAppUserApi();
  const {
    data: appUsers,
    isLoading,
    refresh,
  } = useFetchedData(getAppUsers, []);

  return (
    <Page>
      <TitleBar>
        <PageTitle>Nutzerverwaltung</PageTitle>
        <div>Anzahl der Nutzer: {appUsers.length}</div>
      </TitleBar>
      <AppUserTable
        appUsers={appUsers}
        isLoading={isLoading}
        refresh={refresh}
      />
    </Page>
  );
};

export default AppUserManagement;
