import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import QRCode from 'components/base/QRCode';
import { useDeviceApi } from 'config/api';
import { useFetchedData } from 'hooks/useFetchedData';
import React, { useCallback } from 'react';
import { Spinner } from 'react-activity';
import styled from 'styled-components';

interface Props {
  deviceId: string;
  isVisible: boolean;
  onClose: () => void;
}

const QRCodeDeviceActivation: React.FC<Props> = ({
  deviceId,
  isVisible,
  onClose,
}) => {
  const { getDeviceActivationCode } = useDeviceApi();
  const {
    data: activationCode,
    isLoading,
    error,
  } = useFetchedData(
    useCallback(
      () => getDeviceActivationCode(deviceId),
      [deviceId, getDeviceActivationCode],
    ),
    null,
  );

  return (
    <Modal
      visible={isVisible}
      footer={
        <Button type="primary" onClick={onClose}>
          Schließen
        </Button>
      }
      width={700}
      onCancel={onClose}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Container>
          {!!error && <span>{error}</span>}
          <QRCode
            content={JSON.stringify({
              activationCode: activationCode?.activationCode,
              deviceId: activationCode?.deviceId,
              apiUrl: activationCode?.apiBaseUrl,
            })}
          />
          <Info>
            <Label>Aktivierungscode</Label>
            <span>{activationCode?.activationCode}</span>
            <Label>Device ID</Label>
            <span>{activationCode?.deviceId}</span>
            <Label>Host URL</Label>
            <span>{activationCode?.apiBaseUrl}</span>
          </Info>
        </Container>
      )}
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Info = styled.div`
  font-size: 16px;
`;

const Label = styled.div`
  font-weight: bold;
`;

export default QRCodeDeviceActivation;
