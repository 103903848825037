import { combineReducers, AnyAction } from 'redux';
import auth, { State as AuthState } from './authReducer';

export interface RootState {
  auth: AuthState;
}

const combinedReducer = combineReducers<RootState>({
  auth,
});

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  let newState = state;

  if (action.type === 'PURGE') {
    newState = undefined;
  }

  return combinedReducer(newState, action);
};

export default rootReducer;
