import { Alert, Button, Form, Modal } from 'antd';
import React, { useState } from 'react';

interface Props {
  isModalVisible: boolean;
  onSave: (values: any) => void;
  onCancel: () => void;
  FormElements: React.ReactNode;
}

const CreateElementModal: React.FC<Props> = ({
  isModalVisible,
  onCancel,
  onSave,
  FormElements,
}) => {
  const [error, setError] = useState<Error | null>(null);
  const handleSave = async (values: any) => {
    try {
      await onSave(values);
    } catch (error) {
      setError(error);
    }
  };

  const handleCancel = () => {
    setError(null);
    onCancel();
  };

  const formId = 'create-form';

  return (
    <Modal
      title="Element Erstellen"
      visible={isModalVisible}
      width={1000}
      footer={[
        <Button
          form={formId}
          key="reset"
          htmlType="reset"
          onClick={handleCancel}
        >
          Abbrechen
        </Button>,
        <Button type="primary" form={formId} key="submit" htmlType="submit">
          Speichern
        </Button>,
      ]}
      onCancel={handleCancel}
    >
      <Form
        id={formId}
        name="basic"
        initialValues={{ remember: false }}
        onFinish={handleSave}
      >
        {FormElements}
      </Form>
      {error && (
        <Alert
          message="Error"
          description={error.message}
          type="error"
          showIcon
        />
      )}
    </Modal>
  );
};

export default CreateElementModal;
