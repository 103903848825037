import { Page, PageTitle, TitleBar } from 'components/layout/Page';
import { useDeviceApi } from 'config/api';
import { useFetchedData } from 'hooks/useFetchedData';
import React from 'react';
import DeviceCreator from './DeviceCreator';
import DevicesTable from './DevicesTable';

const DeviceManagement: React.FC = () => {
  const { getDevices } = useDeviceApi();
  const {
    data: devices,
    isLoading,
    error,
    refresh,
  } = useFetchedData(getDevices, []);

  return (
    <Page>
      <TitleBar>
        <PageTitle>Geräteverwaltung</PageTitle>
        <div>Anzahl der Geräte: {devices.length}</div>
        <DeviceCreator refresh={refresh} />
      </TitleBar>
      {error && <div>{error}</div>}
      <DevicesTable devices={devices} refresh={refresh} isLoading={isLoading} />
    </Page>
  );
};

export default DeviceManagement;
