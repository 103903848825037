import api from 'config/api';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface APIVersion {
  version: string;
  revision: string;
  branch: string;
}

const VersionTable: React.FC = () => {
  const [isLoadingApiVersion, setIsLoadingApiVersion] = useState(true);
  const [apiVersion, setApiVersion] = useState<APIVersion | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/version');
        setApiVersion(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingApiVersion(false);
      }
    })();
  }, []);

  return (
    <StyledTable>
      <tbody>
        <tr>
          <th>App Version</th>
          <td>{process.env.REACT_APP_VERSION || 'N/A'}</td>
        </tr>
        <tr>
          <th>API Version</th>
          <td>{isLoadingApiVersion ? '...' : apiVersion?.version || 'N/A'}</td>
        </tr>
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  font-size: 17px;

  th {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: hsl(200, 10%, 60%);
  }
`;

export default VersionTable;
