import { PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import CreateElementModal from 'components/base/table/CreateElementModal';
import { Page, PageTitle, TitleBar } from 'components/layout/Page';
import { useIntegrationConfigApi } from 'config/api';
import { useFetchedData } from 'hooks/useFetchedData';
import useIsDeprecated from 'hooks/useIsDeprecated';
import { IntegrationConfigDto } from 'models/IntegrationConfig';
import React, { useState } from 'react';
import IntegrationForm from './IntegrationForm';
import IntegrationConfigTable from './IntegrationTable';

const IntegrationManagement: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { postIntegrationConfig, getIntegrationConfigs } =
    useIntegrationConfigApi();
  const {
    data: integrationConfigs,
    refresh,
    isLoading,
  } = useFetchedData(getIntegrationConfigs, []);

  const { isDeprecated } = useIsDeprecated();

  return (
    <Page>
      <TitleBar>
        <PageTitle>Schnittstellenverwaltung</PageTitle>

        {isDeprecated ? (
          <Popconfirm
            title={
              <>
                Das Admin Tool wurde nach{' '}
                <a
                  href={
                    'https://admin.voize.de/management/-/integration-configurations'
                  }
                  style={{
                    color: '#1890ff',
                    textDecoration: 'underline',
                  }}
                >
                  admin.voize.de
                </a>{' '}
                umgezogen. Möchtest du diese Schnittstelle trotzdem hier
                erstellen?
              </>
            }
            okText="Ja"
            cancelText="Abbrechen"
            onConfirm={() => setIsModalVisible(true)}
          >
            <Button type={'primary'} icon={<PlusOutlined />}>
              Neu
            </Button>
          </Popconfirm>
        ) : (
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            Neu
          </Button>
        )}

        <CreateElementModal
          isModalVisible={isModalVisible}
          onSave={async (values: IntegrationConfigDto) => {
            await postIntegrationConfig(values);
            refresh();
            setIsModalVisible(false);
          }}
          onCancel={() => setIsModalVisible(false)}
          FormElements={<IntegrationForm />}
        />
      </TitleBar>
      <IntegrationConfigTable
        integrationConfigs={integrationConfigs}
        refresh={refresh}
        isLoading={isLoading}
        isDeprecated={isDeprecated}
      />
    </Page>
  );
};

export default IntegrationManagement;
