import React from 'react';
import { Button, Form, Input, Space, Switch } from 'antd';
import Role from 'models/Role';
import {
  CheckOutlined,
  CloseOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

interface Props {
  initialValue?: Role;
  readonly?: boolean;
}

const RolesForm: React.FC<Props> = ({ initialValue, readonly = false }) => {
  return (
    <>
      <Form.Item
        label="Rollenname"
        name="name"
        rules={[{ required: true, message: 'Geben Sie einen Namen ein.' }]}
        hasFeedback
        initialValue={initialValue?.name}
      >
        <Input readOnly={readonly} />
      </Form.Item>

      <Form.List name="permissions" initialValue={initialValue?.permissions}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Space key={field.key} align="baseline">
                <Form.Item
                  {...field}
                  style={{ width: 500 }}
                  label="Berechtigungsname"
                  name={[field.name, 'name']}
                  rules={[{ required: true, message: 'Fehlender Name' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Berechtigt"
                  name={[field.name, 'isPermitted']}
                  initialValue={
                    initialValue?.permissions[field.key]?.isPermitted
                      ? true
                      : false
                  }
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={
                      initialValue?.permissions[field.key]?.isPermitted
                        ? true
                        : false
                    }
                  />
                </Form.Item>

                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Permission
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default RolesForm;
