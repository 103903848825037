import { PlusOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Select, Tooltip } from 'antd';
import { Option } from 'antd/lib/mentions';
import { useDeviceApi, useDeviceConfigApi } from 'config/api';
import { useFetchedData } from 'hooks/useFetchedData';
import useIsDeprecated from 'hooks/useIsDeprecated';
import _ from 'lodash';
import Device from 'models/Device';
import React, { useState } from 'react';
import styled from 'styled-components';
import QRCodeDeviceActivation from './QRCodeDeviceActivation';
import { COLOR } from 'components/base/globalstyles.styles';

interface Props {
  refresh: () => void;
}

const DeviceCreator: React.FC<Props> = ({ refresh }) => {
  const [newDevice, setNewDevice] = useState<Device>();
  const [error, setError] = useState<string | null>(null);
  const [isQRVisible, setIsQRVisible] = useState(false);
  const { postDevice } = useDeviceApi();
  const { getDeviceConfigs } = useDeviceConfigApi();
  const { data: deviceConfigs, isLoading } = useFetchedData(
    getDeviceConfigs,
    [],
  );
  const [selectedDeviceConfigId, setSelectedDeviceConfigId] = useState<
    string | null
  >(null);

  const { isDeprecated } = useIsDeprecated();

  const createDevice = async () => {
    if (!selectedDeviceConfigId) return;
    try {
      const device = await postDevice(
        `Neues Gerät - ${new Date().toISOString()}`,
        selectedDeviceConfigId,
      );
      setNewDevice(device);
      setIsQRVisible(true);
    } catch (err) {
      setError(err.message);
    } finally {
      refresh();
    }
  };

  return (
    <Container>
      {error && <div>{error}</div>}
      <DeviceConfigSelector>
        <Select
          placeholder="Wählen Sie eine Geräte Konfiguration aus."
          defaultValue={deviceConfigs[0]?._id}
          style={{ width: 350 }}
          onChange={(value) => setSelectedDeviceConfigId(value)}
          loading={isLoading}
        >
          {_.sortBy(deviceConfigs, 'name').map((deviceconfig) => (
            <Option key={deviceconfig._id} value={deviceconfig._id}>
              {deviceconfig.name}
            </Option>
          ))}
        </Select>
      </DeviceConfigSelector>

      {newDevice && (
        <QRCodeDeviceActivation
          deviceId={newDevice._id}
          isVisible={isQRVisible && !!newDevice}
          onClose={() => setIsQRVisible(false)}
        />
      )}

      <Tooltip
        title={
          isDeprecated &&
          'Geräte können nicht mehr hinzugefügt werden, da das Admin Tool umgezogen wurde. Beachte die große rote Warnung!'
        }
      >
        {isDeprecated ? (
          <Popconfirm
            title={
              <>
                Das Admin Tool wurde umgezogen. Möchtest du trotzdem ein Gerät
                hinzufügen?
              </>
            }
            okText="Ja"
            cancelText="Abbrechen"
            onConfirm={createDevice}
          >
            <Button
              icon={<WarningOutlined color="red" />}
              loading={isLoading}
              style={{ backgroundColor: COLOR.MID_GREY }}
            >
              Gerät hinzufügen
            </Button>
          </Popconfirm>
        ) : (
          <Button
            type={'primary'}
            onClick={createDevice}
            icon={<PlusOutlined />}
            disabled={!selectedDeviceConfigId}
            loading={isLoading}
          >
            Gerät hinzufügen
          </Button>
        )}
      </Tooltip>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DeviceConfigSelector = styled.div`
  width: 400px;
`;

export default DeviceCreator;
