import DeviceConfig from './DeviceConfigs';

interface Device {
  _id: string;
  name: string;
  lastSeenAt: string | null;
  info: {
    brand: string;
    manufacturer: string;
    device: string;
    deviceName: string;
    deviceModel: string;
    deviceId: string;
    hardware: string;
    systemName: string;
    systemVersion: string;
    systemBuildId: string;
    appBuildNumber: string;
    appVersion: string;
  } | null;
  deviceConfig: DeviceConfig | null;
}

export interface DeviceDto {
  _id: string;
  name: string;
  info: {
    brand: string;
    manufacturer: string;
    device: string;
    deviceName: string;
    deviceModel: string;
    deviceId: string;
    hardware: string;
    systemName: string;
    systemVersion: string;
    systemBuildId: string;
    appBuildNumber: string;
    appVersion: string;
  } | null;
  deviceConfig: string | null;
}

export const convertToDeviceDto = (device: Device): DeviceDto => {
  return {
    ...device,
    deviceConfig: device.deviceConfig?._id ?? null,
  };
};

export default Device;
