import useIsDeprecated from 'hooks/useIsDeprecated';
import React from 'react';
import styled from 'styled-components';

const DeprecatedWarning: React.FC = () => {
  const { isDeprecated, isLoading } = useIsDeprecated();

  if (isLoading || !isDeprecated) {
    return null;
  }

  return (
    <DeprecationAlert>
      <h1>Achtung!</h1>
      <p>
        Dein Admin Tool wurde auf{' '}
        <a href="https://admin.voize.de">admin.voize.de</a> umgezogen. Bitte
        nimm hier keine Änderung vor. Deine Geräte und Konfigurationen werden
        jetzt über <a href="https://admin.voize.de">admin.voize.de</a>{' '}
        verwaltet. Bei Fragen wende Dich bitte an support@voize.de.
      </p>
    </DeprecationAlert>
  );
};

export default DeprecatedWarning;

const DeprecationAlert = styled.div`
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  a {
    color: #721c24;
    font-weight: bold;
    text-decoration: underline;
  }
`;
