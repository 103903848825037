import { Table } from 'antd';
import DeleteTableElement from 'components/base/table/DeleteTableElement';
import EditTableElement from 'components/base/table/EditTableElement';
import ViewTableElement from 'components/base/table/ViewTableElement';
import { useIntegrationConfigApi } from 'config/api';
import IntegrationConfig from 'models/IntegrationConfig';
import React from 'react';
import IntegrationForm from './IntegrationForm';

interface Props {
  integrationConfigs: IntegrationConfig[];
  isLoading: boolean;
  refresh: () => void;
  isDeprecated: boolean;
}

const IntegrationConfigTable: React.FC<Props> = ({
  integrationConfigs,
  isLoading,
  refresh,
  isDeprecated,
}) => {
  const { deleteIntegratrionConfig, putIntegrationConfig } =
    useIntegrationConfigApi();

  const columns = [
    {
      title: 'Name',
      sorter: true,
      key: 'name',
      render: (element: IntegrationConfig) => element.name,
    },
    {
      title: 'Type',
      key: 'type',
      render: (element: IntegrationConfig) => element.type,
    },
    {
      title: 'Vivendi Proxi Api URL',
      key: 'baseurl',
      render: (element: IntegrationConfig) => element.baseUrl,
    },
    {
      title: 'Sync Username',
      key: 'syncusername',
      render: (element: IntegrationConfig) => element.syncUsername,
    },
    {
      title: 'Aktionen',
      key: 'actions',
      // eslint-disable-next-line react/display-name
      render: (element: IntegrationConfig) => (
        <>
          <DeleteTableElement
            element={element}
            renderElement={() => `${element.name} (${element._id})`}
            onDelete={async () => await deleteIntegratrionConfig(element._id)}
            refresh={refresh}
          />
          <EditTableElement
            element={element}
            onSubmit={async (id: string, element: IntegrationConfig) =>
              await putIntegrationConfig(id, element)
            }
            FormElements={<IntegrationForm initialValue={element} />}
            refresh={refresh}
            showDeprecationWarning={isDeprecated}
            newUrl="https://admin.voize.de/management/-/integration-configurations"
          />
          <ViewTableElement
            element={element}
            FormElements={<IntegrationForm readonly={true} />}
          />
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey={(element: IntegrationConfig) => element._id}
      dataSource={integrationConfigs}
      loading={isLoading}
      pagination={false}
    />
  );
};

export default IntegrationConfigTable;
