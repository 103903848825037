import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { AiOutlineDelete } from 'react-icons/ai';
import AntIconWrapper from '../AntIconWrapper';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface Props<T, K> {
  element: T;
  renderElement: () => string;
  onDelete: (element: T) => Promise<K>;
  refresh: () => void;
}

export function DeleteTableElement<T, K>({
  element,
  onDelete,
  refresh,
  renderElement,
}: Props<T, K>) {
  const [error, setError] = useState<string | null>(null);

  useEffect(() => setError(null), [element]);

  const handleDelete = () => {
    onDelete(element)
      .then(() => {
        refresh();
      })
      .catch((err) => {
        setError(`Could not delete the Element. The server responded ${err}`);
      });
  };

  const { confirm } = Modal;

  function showDeleteConfirm() {
    confirm({
      title: `Bist du sicher das ${renderElement()} gelöscht werden soll?`,
      icon: <ExclamationCircleOutlined />,
      content: `Das Element wird unwiederruflich gelöscht. ${error}`,
      okText: 'Ja, Löschen',
      okType: 'danger',
      cancelText: 'Nein',
      onOk() {
        handleDelete();
      },
      onCancel() {},
    });
  }

  return (
    <>
      <Button type="link" size="small" danger onClick={showDeleteConfirm}>
        <AntIconWrapper>
          <AiOutlineDelete />
        </AntIconWrapper>
      </Button>
    </>
  );
}

export default DeleteTableElement;
