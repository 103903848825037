import React from 'react';
import { useRoleApi } from 'config/api';
import Role from 'models/Role';
import { Table } from 'antd';
import TableActionsList from 'components/base/table/TableActionsList';
import RolesForm from './RolesForm';

interface Props {
  roles: Role[];
  isLoading: boolean;
  refresh: () => void;
}

const RolesTable: React.FC<Props> = ({ roles, isLoading, refresh }) => {
  const { deleteRole, putRole } = useRoleApi();

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (element: Role) => element.name,
    },
    {
      title: 'Aktionen',
      key: 'actions',
      // eslint-disable-next-line react/display-name
      render: (element: Role) => (
        <TableActionsList
          element={element}
          renderElementNameForDelete={() => `${element.name}`}
          refresh={refresh}
          onDelete={async () => await deleteRole(element._id)}
          onSubmit={async (id: string, element: Role) =>
            await putRole(id, element)
          }
          FormElements={RolesForm}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey={(element: Role) => element._id}
      dataSource={roles}
      loading={isLoading}
      pagination={false}
    />
  );
};

export default RolesTable;
