import React from 'react';
import { Form, Input, Select } from 'antd';
import { useRoleApi } from 'config/api';
import { useFetchedData } from 'hooks/useFetchedData';
import AppUser from 'models/AppUser';
import _ from 'lodash';

interface Props {
  initialValue?: AppUser;
  readonly?: boolean;
}

const AppUserForm: React.FC<Props> = ({ initialValue, readonly = false }) => {
  const { getRoles } = useRoleApi();
  const { data: roles } = useFetchedData(getRoles, []);

  return (
    <>
      <Form.Item
        label="Vorname"
        name="firstname"
        rules={[{ required: true, message: 'Geben Sie ihren Vornamen ein.' }]}
        hasFeedback
        initialValue={initialValue?.firstname}
      >
        <Input readOnly={readonly} />
      </Form.Item>

      <Form.Item
        label="Nachname"
        name="lastname"
        rules={[{ required: true, message: 'Geben Sie ihren Nachnamen ein.' }]}
        hasFeedback
        initialValue={initialValue?.lastname}
      >
        <Input readOnly={readonly} />
      </Form.Item>

      <Form.Item
        label="Username (Kürzel)"
        tooltip="Username oder Kürzel, das für den Login bei Ihrem Dokumentationssystem (z.B. Vivendi PD) genutzt werden soll."
        name="username"
        rules={[
          { required: true, message: 'Geben Sie Ihr Username/Kürzel ein.' },
        ]}
        hasFeedback
        initialValue={initialValue?.username}
      >
        <Input readOnly={readonly} />
      </Form.Item>
      <Form.Item
        label="Rolle"
        name="role"
        tooltip="Die gewählte Rolle bestimmt die Berechtigungen und angezeigten Funktionen in der voize App."
        rules={[{ required: true, message: 'Wählen Sie eine Rolle.' }]}
        hasFeedback
        initialValue={initialValue?.role?._id}
      >
        <Select placeholder="Wählen Sie eine Rolle.">
          {_.sortBy(roles, 'name').map((role) => (
            <Select.Option key={role._id} value={role._id}>
              {role.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default AppUserForm;
