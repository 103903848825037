import axios from 'axios';
import ClientOAuth2 from 'client-oauth2';
import ActivationCodeResponse from 'models/ActivationCode';
import AppUser, { AppUserDto } from 'models/AppUser';
import Device, { DeviceDto } from 'models/Device';
import DeviceConfig, { DeviceConfigDto } from 'models/DeviceConfigs';
import IntegrationConfig, {
  IntegrationConfigDto,
} from 'models/IntegrationConfig';
import LocationNode from 'models/Location';
import Organization from 'models/Organization';
import Role from 'models/Role';
import { useCallback } from 'react';
import { useAccessToken } from '../state/authReducer';
import { allScopes } from './scopes';

function getBaseUrl(): URL {
  const url = new URL(window.location.href);
  url.hash = '';
  url.username = '';
  url.password = '';
  url.search = '';
  url.pathname = '';
  return url;
}

function getApiBaseUrl(): URL {
  if (process.env.REACT_APP_API_URL) {
    return new URL(process.env.REACT_APP_API_URL);
  } else {
    const url = getBaseUrl();
    url.hostname = `api.${url.hostname}`;
    return url;
  }
}

export const adminApiAuth = new ClientOAuth2({
  clientId: 'admin-app',
  clientSecret: 'TODO',
  accessTokenUri: new URL('oauth/token', getApiBaseUrl()).toString(),
  redirectUri: new URL('callback', getBaseUrl()).toString(),
  scopes: allScopes,
});

const api = axios.create({
  baseURL: getApiBaseUrl().toString(),
});

const useHeaders = () => {
  const accessToken = useAccessToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return headers;
};

// TODO fix headers hook deps
/* eslint-disable  react-hooks/exhaustive-deps*/

export const useDeviceConfigApi = () => {
  const headers = useHeaders();
  const getDeviceConfigs = useCallback(async (): Promise<DeviceConfig[]> => {
    return (await api.get('v2/admin/device-configs', { headers })).data;
  }, []);

  const getDeviceConfigById = useCallback(
    async (id: string): Promise<DeviceConfig> => {
      return (await api.get(`v2/admin/device-configs/${id}`, { headers })).data;
    },
    [],
  );

  const putDeviceConfig = useCallback(
    async (
      id: string,
      deviceConfig: DeviceConfigDto,
    ): Promise<DeviceConfig> => {
      console.log(deviceConfig);
      const { data } = await api.put(
        `/v2/admin/device-configs/${id}`,
        deviceConfig,
        { headers },
      );
      return data;
    },
    [],
  );

  const deleteDeviceConfig = useCallback(
    async (id: string): Promise<DeviceConfig> => {
      return (await api.delete(`v2/admin/device-configs/${id}`, { headers }))
        .data;
    },
    [],
  );

  const postDeviceConfig = async (
    deviceConfig: DeviceConfigDto,
  ): Promise<Device> => {
    return (
      await api.post(`/v2/admin/device-configs`, deviceConfig, { headers })
    ).data;
  };

  const migrateDeviceConfig = async (id: string): Promise<Device> => {
    return (
      await api.post(`/v2/admin/device-configs/${id}/migrate`, undefined, {
        headers,
      })
    ).data;
  };

  return {
    getDeviceConfigs,
    getDeviceConfigById,
    putDeviceConfig,
    deleteDeviceConfig,
    postDeviceConfig,
    migrateDeviceConfig,
  };
};

export const useDeviceApi = () => {
  const headers = useHeaders();
  const getDevices = useCallback(async (): Promise<Device[]> => {
    return (await api.get('v2/admin/devices', { headers })).data;
  }, []);

  const getDeviceById = useCallback(async (id: string): Promise<Device> => {
    return (await api.get(`v2/admin/devices/${id}`, { headers })).data;
  }, []);

  const putDevice = useCallback(
    async (id: string, device: DeviceDto): Promise<Device> => {
      const { data } = await api.put(`/v2/admin/devices/${id}`, device, {
        headers,
      });
      return data;
    },
    [],
  );

  const deleteDevice = useCallback(async (id: string): Promise<Device> => {
    return (await api.delete(`v2/admin/devices/${id}`, { headers })).data;
  }, []);

  const postDevice = async (
    name: string,
    deviceConfigId: string,
  ): Promise<Device> => {
    return (
      await api.post(
        `/v2/admin/devices`,
        {
          name,
          deviceConfig: deviceConfigId,
        },
        { headers },
      )
    ).data;
  };

  const getDeviceActivationCode = useCallback(
    async (id: string): Promise<ActivationCodeResponse> => {
      return (
        await api.get(`/v2/admin/devices/${id}/activation-code`, {
          headers,
        })
      ).data;
    },
    [],
  );

  return {
    getDevices,
    getDeviceById,
    deleteDevice,
    putDevice,
    postDevice,
    getDeviceActivationCode,
  };
};

export const useRoleApi = () => {
  const headers = useHeaders();

  const getRoles = useCallback(async (): Promise<Role[]> => {
    return (await api.get('v2/admin/roles', { headers })).data;
  }, []);

  const getRoleById = useCallback(async (id: string): Promise<Role> => {
    return (await api.get(`v2/admin/roles/${id}`, { headers })).data;
  }, []);

  const putRole = useCallback(async (id: string, role: Role): Promise<Role> => {
    return (await api.put(`/v2/admin/roles/${id}`, role, { headers })).data;
  }, []);

  const deleteRole = useCallback(async (id: string): Promise<Role> => {
    return (await api.delete(`v2/admin/roles/${id}`, { headers })).data;
  }, []);

  const postRole = useCallback(async (data: Role): Promise<Role> => {
    return await (
      await api.post(`v2/admin/roles`, data, { headers })
    ).data;
  }, []);

  return {
    getRoles,
    getRoleById,
    putRole,
    postRole,
    deleteRole,
  };
};

export const useAppUserApi = () => {
  const headers = useHeaders();

  const getAppUsers = useCallback(async (): Promise<AppUser[]> => {
    return (await api.get('v2/admin/app-users', { headers })).data;
  }, []);

  const getAppUserById = useCallback(async (id: string): Promise<AppUser> => {
    return (await api.get(`/v2/admin/app-users/${id}`, { headers })).data;
  }, []);
  const putAppUser = useCallback(
    async (id: string, appUser: AppUser): Promise<AppUser> => {
      return (await api.put(`/v2/admin/app-users/${id}`, appUser, { headers }))
        .data;
    },
    [],
  );

  const postAppUser = useCallback(
    async (data: AppUserDto): Promise<AppUser> => {
      return await (
        await api.post(`v2/admin/app-users`, data, { headers })
      ).data;
    },
    [],
  );

  const deleteAppUser = useCallback(async (id: string): Promise<AppUser> => {
    return (await api.delete(`v2/admin/app-users/${id}`, { headers })).data;
  }, []);

  return {
    getAppUsers,
    getAppUserById,
    putAppUser,
    deleteAppUser,
    postAppUser,
  };
};

export const useIntegrationConfigApi = () => {
  const headers = useHeaders();

  const getIntegrationConfigs = useCallback(async (): Promise<
    IntegrationConfig[]
  > => {
    return (await api.get('/v2/admin/integration-configs', { headers })).data;
  }, []);

  const postIntegrationConfig = async (
    integrationConfig: IntegrationConfigDto,
  ): Promise<IntegrationConfig> => {
    console.log(integrationConfig);
    return (
      await api.post(
        `/v2/admin/integration-configs`,
        { ...integrationConfig },
        { headers },
      )
    ).data;
  };

  const putIntegrationConfig = useCallback(
    async (id: string, data: IntegrationConfig): Promise<IntegrationConfig> => {
      return (
        await api.put(`/v2/admin/integration-configs/${id}`, data, { headers })
      ).data;
    },
    [],
  );

  const deleteIntegratrionConfig = useCallback(
    async (id: string): Promise<IntegrationConfig> => {
      return (
        await api.delete(`v2/admin/integration-configs/${id}`, { headers })
      ).data;
    },
    [],
  );

  return {
    putIntegrationConfig,
    deleteIntegratrionConfig,
    getIntegrationConfigs,
    postIntegrationConfig,
  };
};

export const useOrganizationApi = () => {
  const headers = useHeaders();
  const getOrganization = useCallback(async (): Promise<Organization> => {
    return (await api.get('/v2/admin/organization', { headers })).data;
  }, []);

  return {
    getOrganization,
  };
};

export const useLocationsApi = () => {
  const headers = useHeaders();
  const getLocations = useCallback(
    async (integrationConfigId: string): Promise<LocationNode[]> => {
      return (
        await api.get(`/v2/admin/locations/${integrationConfigId}`, { headers })
      ).data;
    },
    [],
  );

  return {
    getLocations,
  };
};

export default api;
