import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { adminApiAuth } from 'config/api';

const SignIn: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        // TODO any is required as token.expires is part of the response but not included in the TS Type.
        const token: any = await adminApiAuth.owner.getToken(
          values.username,
          values.password,
        );

        dispatch({
          type: 'LOGIN',
          token: token.accessToken,
          expires: token.expires,
        });
      } catch (error) {
        if (!error.body) {
          setError('Der Server ist momentan nicht erreichbar.');
          return;
        }
        if (error.body.error === 'invalid_grant') {
          setError('Die angegebenen Anmeldedaten sind nicht korrekt.');
        }
        if (error.body.error === 'invalid_request') {
          setError('Nutzername oder Passwort fehlen.');
        }
      }
    },
  });

  return (
    <Container>
      <ContainerInner>
        <Title>Anmeldung</Title>
        {error && <ErrorText>{error}</ErrorText>}
        <form onSubmit={formik.handleSubmit}>
          <FormRow>
            <Label htmlFor="username">Benutzername</Label>
            <Input
              id="username"
              name="username"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.username}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="password">Passwort</Label>
            <Input
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
          </FormRow>
          <FormRow>
            <Button type="submit">Anmelden</Button>
          </FormRow>
        </form>
      </ContainerInner>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsl(200, 10%, 95%);
`;

const ContainerInner = styled.div`
  width: 440px;
  background-color: white;
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  margin-bottom: 30px;
  font-size: 36px;
`;

const ErrorText = styled.div`
  color: hsl(0, 100%, 60%);
  margin-bottom: 10px;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
`;

const Input = styled.input`
  margin-bottom: 14px;
  background-color: hsl(200, 10%, 95%);
`;

const Button = styled.button`
  margin-top: 10px;
  align-self: flex-end;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  background-color: hsl(100, 60%, 50%);
  color: white;
  font-weight: 500;
  border-radius: 5px;
`;

export default SignIn;
