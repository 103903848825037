import { EyeOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import Form from 'antd/lib/form/Form';
import React, { useState } from 'react';
import AntIconWrapper from '../AntIconWrapper';

interface Props<T> {
  element: T; // TODO not used
  FormElements: React.ReactNode;
}

export function ViewTableElement<T>({ FormElements }: Props<T>) {
  const [isModalVisible, setIsModalVisble] = useState(false);

  const handleCancel = () => {
    setIsModalVisble(false);
  };

  return (
    <>
      {isModalVisible && (
        <Modal
          title="Element Details"
          visible={isModalVisible}
          width={1000}
          onCancel={handleCancel}
          footer={[
            <Button key={'close'} onClick={handleCancel}>
              Schließen
            </Button>,
          ]}
        >
          <Form id="my-form" name="basic">
            {FormElements}
          </Form>
        </Modal>
      )}
      <Button type="link" size="small" onClick={() => setIsModalVisble(true)}>
        <AntIconWrapper>
          <EyeOutlined />
        </AntIconWrapper>
      </Button>
    </>
  );
}

export default ViewTableElement;
